import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';

const query = graphql`
  query {
    wall01: file(relativePath: { eq: "wall-01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 620) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
    wall02: file(relativePath: { eq: "wall-02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 620) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
    wall03: file(relativePath: { eq: "wall-03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 620) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
    wall04: file(relativePath: { eq: "wall-04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 620) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
    wall05: file(relativePath: { eq: "wall-05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 620) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
    wall06: file(relativePath: { eq: "wall-06.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 620) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
  }
`;

const IndexFeatures = () => {
  const { t } = useTranslation();

  return (
    <StaticQuery
      query={query}
      render={data => (
        <div className="row mt-10">
          <div className="col-sm-6 offset-xl-1">
            <div className="media-grid">
              <div className="row">
                {[1, 3, 5].map(i => (
                  <div className="col-4" key={i}>
                    <Fade
                      left
                      distance="50px"
                      delay={i * 100}
                      duration={500}
                      fraction={0.3}
                    >
                      <picture>
                        <source
                          srcSet={
                            data[`wall0${i}`].childImageSharp.fluid.srcSet
                          }
                          sizes={data[`wall0${i}`].childImageSharp.fluid.sizes}
                        />
                        <img
                          src={data[`wall0${i}`].childImageSharp.fluid.src}
                          alt="Example d'une séance de coaching"
                          className="img-fluid"
                        />
                      </picture>
                    </Fade>
                    <Fade left distance="50px" delay={i * 100} duration={500}>
                      <picture>
                        <source
                          srcSet={
                            data[`wall0${i + 1}`].childImageSharp.fluid.srcSet
                          }
                          sizes={
                            data[`wall0${i + 1}`].childImageSharp.fluid.sizes
                          }
                        />
                        <img
                          src={data[`wall0${i + 1}`].childImageSharp.fluid.src}
                          alt="Example d'une séance de coaching"
                          className="img-fluid mt-md-2"
                        />
                      </picture>
                    </Fade>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-5 col-xl-4 offset-md-1 mt-4 mt-sm-0 mt-md-6">
            <h2 className="h3">{t('index.features.title')}</h2>
            <ul className="list-unstyled mt-3">
              <li className="pt-1">
                <Fade
                  top
                  distance="20px"
                  delay={300}
                  duration={500}
                  fraction={1}
                >
                  <div>
                    <small className="text-success pr-1">
                      <Icon icon="check" />
                    </small>{' '}
                    {t('index.features.bullet.1')}
                  </div>
                </Fade>
              </li>
              <li className="pt-1">
                <Fade
                  top
                  distance="20px"
                  delay={350}
                  duration={500}
                  fraction={1}
                >
                  <div>
                    <small className="text-success pr-1">
                      <Icon icon="check" />
                    </small>{' '}
                    {t('index.features.bullet.2')}
                  </div>
                </Fade>
              </li>
              <li className="pt-1">
                <Fade
                  top
                  distance="20px"
                  delay={400}
                  duration={500}
                  fraction={1}
                >
                  <div>
                    <small className="text-success pr-1">
                      <Icon icon="check" />
                    </small>{' '}
                    {t('index.features.bullet.3')}
                  </div>
                </Fade>
              </li>
            </ul>
          </div>
        </div>
      )}
    />
  );
};

export default IndexFeatures;
