import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import { ReactComponent as CheckCircle } from 'assets/svg/check-circle.svg';

const query = graphql`
  query {
    desktop: file(name: { eq: "coach" }) {
      publicURL
    }
    mobile: file(name: { eq: "coach-mobile" }) {
      publicURL
    }
  }
`;

const IndexCoach = () => {
  const { t } = useTranslation();

  return (
    <StaticQuery
      query={query}
      render={data => (
        <Fade bottom distance="30px" duration={500}>
          <div className="cover-content mt-5 mt-md-10">
            <picture>
              <source
                srcSet={data.mobile.publicURL}
                media="(max-width: 570px)"
              />
              <img
                src={data.desktop.publicURL}
                alt="Screenshot of Hitch application on iPhone"
                className="img-fluid"
              />
            </picture>
            <div>
              <div className="text-white">
                <p className="text-uppercase">
                  <CheckCircle
                    className="mr-1 position-relative"
                    style={{ top: '-0.1rem' }}
                  />
                  {t('index.coach.title')}
                </p>
                <h2>{t('index.coach.description')}</h2>
                <ul className="list-unstyled mt-3">
                  <li className="pt-1">
                    <Fade
                      top
                      distance="20px"
                      delay={300}
                      duration={500}
                      fraction={1}
                    >
                      <div>
                        <small className="text-success pr-1">
                          <Icon icon="check" />
                        </small>{' '}
                        {t('index.coach.bullet.1')}
                      </div>
                    </Fade>
                  </li>
                  <li className="pt-1">
                    <Fade
                      top
                      distance="20px"
                      delay={300}
                      duration={500}
                      fraction={1}
                    >
                      <div>
                        <small className="text-success pr-1">
                          <Icon icon="check" />
                        </small>{' '}
                        {t('index.coach.bullet.2')}
                      </div>
                    </Fade>
                  </li>
                  <li className="pt-1">
                    <Fade
                      top
                      distance="20px"
                      delay={300}
                      duration={500}
                      fraction={1}
                    >
                      <div>
                        <small className="text-success pr-1">
                          <Icon icon="check" />
                        </small>{' '}
                        {t('index.coach.bullet.3')}
                      </div>
                    </Fade>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Fade>
      )}
    />
  );
};

IndexCoach.propTypes = {};

export default IndexCoach;
