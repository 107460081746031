import React from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import { ReactComponent as LetsgoFitness } from 'assets/svg/letsgo-fitness.svg';
import { ReactComponent as FitsPro } from 'assets/svg/fits-pro.svg';

const IndexPartners = () => {
  const { t } = useTranslation();

  return (
    <div className="row mt-5 mt-md-9">
      <div className="col-sm-7 col-xl-6 mx-auto text-center">
        <h3 className="h2">{t('index.partners.title')}</h3>

        <div className="row justify-content-center">
          <div className="col-5">
            <Fade bottom distance="20px" duration={500} fraction={1}>
              <div>
                <a
                  href="https://www.letsgofitness.ch/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LetsgoFitness className="img-fluid" />
                </a>
              </div>
            </Fade>
          </div>
          <div className="col-5 offset-1">
            <Fade
              bottom
              distance="20px"
              duration={500}
              delay={200}
              fraction={1}
            >
              <div>
                <a
                  href="https://www.fitspro.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FitsPro className="img-fluid" />
                </a>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

IndexPartners.propTypes = {};

export default IndexPartners;
