import React from 'react';
import { useTranslation } from 'react-i18next';

import AppStoreBadge from '../AppStoreBadge';

const IndexBottom = () => {
  const { t } = useTranslation();

  return (
    <div className="row mt-5 mt-6">
      <div className="col-sm-7 col-xl-6 mx-auto text-center">
        <div>
          <div className="row">
            <h2 className="mb-3">{t('appstore.description')}</h2>
            <div className="col-sm-10 mx-auto text-center">
              <AppStoreBadge height="45px" />
            </div>
          </div>
        </div>

        {/* <div>
        <div className="mt-5 mt-md-10">
          <a href="#" className="btn btn-light mr-1">
            <Icon icon="instagram" />
            Instagram
          </a>
          <a href="#" className="btn btn-light">
            <Icon icon="facebook" />
            Facebook
          </a>
        </div>
      </div> */}
      </div>
    </div>
  );
};

IndexBottom.propTypes = {};

export default IndexBottom;
