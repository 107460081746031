import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';

import AppStoreBadge from 'components/AppStoreBadge';

export const query = graphql`
  query {
    desktop: file(name: { eq: "iphone" }) {
      publicURL
    }
    mobile: file(name: { eq: "iphone-mobile" }) {
      publicURL
    }
    appstore: file(name: { eq: "apple-appstore-fr" }) {
      publicURL
    }
    googleplaystore: file(name: { eq: "google-play-badge-fr" }) {
      publicURL
    }
  }
`;

const IndexIntro = () => {
  const { t } = useTranslation();

  return (
    <StaticQuery
      query={query}
      render={data => (
        <div className="row">
          <div className="col-sm-6 col-xl-5 offset-xl-1 py-sm-2 py-md-6">
            <h1>{t('index.intro.title')}</h1>
            <p className="text-lg my-4">{t('appstore.description')}</p>

            <div className="mt-3">
              <AppStoreBadge />
            </div>
          </div>

          <div className="col-sm-5 col-xl-4 offset-sm-1 mt-6 mt-sm-0">
            <div className="iphone-screen">
              <picture>
                <source
                  srcSet={data.mobile.publicURL}
                  media="(max-width: 570px)"
                />
                <img
                  src={data.desktop.publicURL}
                  alt="Screenshot of Hitch application on iPhone"
                  className="img-fluid"
                />
              </picture>
            </div>
          </div>
        </div>
      )}
    />
  );
};

IndexIntro.propTypes = {};

export default IndexIntro;
