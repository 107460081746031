import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import SEO from 'components/Seo';
import Layout from 'components/Layout';
import IndexIntro from 'components/Sections/IndexIntro';
import IndexFeatures from 'components/Sections/IndexFeatures';
import IndexCoach from 'components/Sections/IndexCoach';
import IndexPartners from 'components/Sections/IndexPartners';
import IndexBottom from 'components/Sections/IndexBottom';

import 'locales/i18n';

const IndexPage = ({ pageContext, location }) => {
  const { t, i18n } = useTranslation();
  const defaultLng = i18n.options.fallbackLng[0];

  useEffect(() => {
    i18n.changeLanguage(pageContext.lng || defaultLng);
  }, []);

  return (
    <Layout location={location}>
      <SEO
        title={t('index.title')}
        lang={i18n.language || 'fr'}
        metaTitle={t('meta.title')}
        metaDescription={t('meta.description')}
      />
      <IndexIntro />
      <IndexFeatures />
      <IndexCoach />
      <IndexPartners />
      <IndexBottom />
    </Layout>
  );
};

IndexPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
IndexPage.defaultProps = {};

export default IndexPage;
