import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import './AppStoreBadge.scss';

export const query = graphql`
  query {
    appstore: file(name: { eq: "apple-appstore-fr" }) {
      publicURL
    }
    googleplaystore: file(name: { eq: "google-play-badge-fr" }) {
      publicURL
    }
  }
`;

const AppStoreBadge = ({ height }) => (
  <StaticQuery
    query={query}
    render={data => (
      <>
        <a
          href="https://apps.apple.com/ch/app/hitch-fitness-coach/id1486756369?itsct=apps_box_link&itscg=30200"
          className="js-download-badge js-appstore-badge"
          style={{
            display: 'block',
            float: 'left',
            marginRight: '16px',
            marginBottom: '16px',
          }}
        >
          <img
            src={data.appstore.publicURL}
            alt="Télécharger dans l'App Store"
            height={height}
          />
        </a>
        {/* Google Play */}
        <a
          href="https://play.google.com/store/apps/details?id=com.wearehitch.hitch.android"
          className="js-download-badge js-googleplaystore-badge"
        >
          <img
            src={data.googleplaystore.publicURL}
            alt="Disponible sur Google Play"
            height={height}
          />
        </a>
      </>
    )}
  />
);

AppStoreBadge.propTypes = {
  height: PropTypes.string,
};

AppStoreBadge.defaultProps = {
  height: '60px',
};

export default AppStoreBadge;
